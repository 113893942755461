import React from "react"
import HeaderCloud from "./cloud-server/HeaderCloud"
import Seo from "../../components/seo"
import CloudServerFeatures from "./cloud-server/CloudServerFeatures"
import FaqCloudServer from "./cloud-server/FaqCloudServer"
import { Link } from "gatsby"

const CloudPage = () => {
  return (
    <div className="content bg">
      <Seo
        title="Billing Software, EPOS Software in Chennai, India"
        desc="Motherepos offers EPOS Software at competitive prices."
        keywords="Billing Software, EPOS Software, pos software"
      />

      <HeaderCloud />
      <section className="pricing text-start">
        <div className="container-xl">
          <div className="row justify-content-center">
            <div className="col-12 col-md-3 mb-2">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title mb-0 fs-20">
                    Managed - Cloud Server
                  </h3>
                  <div className="small-text mb-3">Tier IV Datacenter</div>
                  <div className="card-text">
                    <div className="fw-bolder">Starting at</div>
                    <div className="fs-32 fw-bolder mb-3">Rs. 2500/m</div>
                  </div>
                  <Link
                    to="/cloud/managed-cloud-server/"
                    className="stretched-link btn btn-outline-dark"
                  >
                    Deploy Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title mb-0 fs-20">
                    Un Managed - Cloud Server
                  </h3>
                  <div className="small-text mb-3">Tier III Datacenter</div>
                  <div className="card-text">
                    <div className="fw-bolder">Starting at</div>
                    <div className="fs-32 fw-bolder mb-3">Rs. 350/m</div>
                  </div>
                  <Link
                    to="/cloud/unmanaged-cloud-server/"
                    className="stretched-link btn btn-outline-dark"
                  >
                    Deploy Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title mb-0 fs-20">MySQL Cloud</h3>
                  <div className="small-text mb-3">
                    Suites for Windows Application
                  </div>
                  <div className="card-text">
                    <div className="fw-bolder">Starting at</div>
                    <div className="fs-32 fw-bolder mb-3">Rs. 99/m</div>
                  </div>
                  <Link
                    to="/cloud/mysql-database/"
                    className="stretched-link btn btn-outline-dark"
                  >
                    MySQL Database
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title mb-0 fs-20">MSSQL Cloud</h3>
                  <div className="small-text mb-3">
                    Suites for Windows Application
                  </div>
                  <div className="card-text">
                    <div className="fw-bolder">Starting at</div>
                    <div className="fs-32 fw-bolder mb-3">Rs. 250/m</div>
                  </div>
                  <Link
                    to="/cloud/mssql-database/"
                    className="stretched-link btn btn-outline-dark"
                  >
                    MSSQL Database
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CloudServerFeatures />
      <FaqCloudServer />
    </div>
  )
}

export default CloudPage
